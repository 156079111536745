import { cn, ENUM_STATUS_CODE, toAbsoluteUrl } from "src/helpers/index";
import { Menu } from "./modules";
import { Link, useLocation } from "react-router-dom";
import {

  ChevronRight,
  LogOut,
  Mail,
  Phone,
  X,
} from "lucide-react";
import {  useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  dashboardReducerState,
  
} from "src/redux/Slicer/dashboardSlicer";
import { RootState } from "src/redux/store";
import Logo from "../../../assets/images/NPCL Saur Mitra 512w (1).png";
import { useAuth } from "src/modules/Auth/Core";
import { Button } from "src/components/ui/button";


export interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const OCT_LOGO=toAbsoluteUrl('/asset/image/OCT_Logo_Horizontal.png')

const Sidebarlayout = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const [open, setOpen] = useState(true);
  const { auth, logout,logoutHandling,logoutLoading } = useAuth();
const dispatch=useDispatch()
  const { dashBoardState } = useSelector((state: RootState) =>
    dashboardReducerState(state)
  );
  const { pathname } = useLocation();

  const menuOptions = Menu;

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-9999 flex h-screen w-72 flex-col overflow-hidden bg-primaryDarkBlue duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0  ${
        sidebarOpen ? "translate-x-0 " : "-translate-x-full"
      }`}
    >
      <div className="w-full  h-full">
        <div className=" mt-2 md:mt-0 flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
          <div>
            <img src={Logo} alt="Logo" className="w-[80%]"/>
          </div>

          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden"
          >
            <X size={20} className="text-white" />
          </button>
        </div>
        <div className=" flex flex-col h-[55%]  scrollbar-thumb-[#026597] scrollbar-track-sky-600 scrollbar-thin overflow-y-scroll duration-300 ease-linear pl-4">
          <ul className={`pt-6 space-y-1 }`}>
            {Menu.map((menu, index) => (
              <li key={index}>
                {menu.options !== undefined ? (
                  <div>
                    <p className="flex items-center space-x-4 cursor-pointer">
                      <span>{menu.iconName(cn("text-white font-bold"))}</span>
                      <span className={cn("text-white")}>{menu.name}</span>
                    </p>
                    <ul
                      className={cn("ml-8 mt-2", { "ml-2": !dashBoardState })}
                    >
                      {menu.options.map((option, optionIndex) => (
                        <li key={optionIndex}>
                          <Link
                            to={option.path}
                            className="flex items-center space-x-4 space-y-2"
                          >
                            {/* <span
                        >{option.iconName(cn('text-white font-bold mt-2'))}
                        </span> */}
                            <span className={cn("text-white text-xs ")}>
                              {option.name}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link
                    to={menu.path}
                    className={`flex items-center justify-between space-x-4  py-[12px] px-4 rounded-l-full ${
                      menu.path && pathname.includes(menu.path)
                        ? "bg-white text-text_primaryDarkBlue"
                        : ""
                    }`}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                  >
                    {/* <span>{menu.iconName(cn('text-white text-sm font-bold', { 'text-textLightBlack': menu.path && pathname.includes(menu.path) }))}
                </span> */}
                    <span
                      className={cn("text-white font-normal text-[13px]", {
                        "text-text_primaryDarkBlue text-sm font-semibold":
                          menu.path && pathname.includes(menu.path),
                      })}
                    >
                      {menu.name}
                    </span>
                    <span>
                      {menu.path && pathname.includes(menu.path) ? (
                        <ChevronRight size={20} />
                      ) : (
                        <></>
                      )}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="absolute bottom-12 space-y-6">
        <div className=" cursor-pointer " onClick={() =>  logoutHandling()}>
          {/* <span>Logout</span> <LogOut className='w-4 h-4'/>  */}
          <Button className="bg-light_red hover:bg-light_red ml-6 text-white flex items-center space-x-2" disabled={logoutLoading}>
           {logoutLoading?"Please Wait":<><span>Logout</span> <LogOut className="w-4 h-4" /></>} 
          </Button>
        </div>
        <div className=" text-white text-xs space-y-2 ">
          <div className="flex items-center ml-8 space-x-2">In association with</div>
          <div className="flex items-center ml-6 space-x-2 ">
            <img src={OCT_LOGO} alt='oct-logo' className="w-[126px] h-[35px]"/>
          </div>
          <div className="flex items-center ml-6 space-x-2">
            <Mail className="text-white w-4 h-4" />
            <div>CRMNPCL@rpsg.in</div>
          </div>
          <div className="flex items-center ml-6 space-x-2">
            <Phone className="text-white w-4 h-4" />

            <div>0120 6226666 <span className="text-white/50">(24 x 7)</span></div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebarlayout;
