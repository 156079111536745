import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { error } from "console";
// import { getAllPlayerUserId } from "src/service/services";
import { RootState } from "../store";

import {
  getDropdownConfigtData,
  getTimeBlockDropDownApi,
} from "../../service/services";

import { toast } from "sonner";
import { stringifyObjectToRequestQuery } from "src/helpers";
import { updateError, updateErrorCode } from "./errorHadlingSlicer";
import { act } from "react-dom/test-utils";

interface Idata {
  user_id: number;
  name: string;
  id: string;
}

interface MarketTypeObj{
  
    marketType: string,
    startTime: string,
    endTime: string

}
const IMarketTypeObj={
  
  marketType: '',
  startTime: '',
  endTime: ''

}
interface InitialState {
  error: boolean;
  isLoading: boolean;
  message: string | undefined;
  dataChanged: boolean;
  userIdData: Idata[] | [];
  userId: number;
  states: string | undefined;
  stateId: string | undefined;
  discomeId: string | undefined;
  feederdropdown: any[];
  substationdropdown: any[];
  tranformerdropdown: any[];
  solarTypedropdown: any[];
  meterNoDropdown: any[];
  solarMfgdropdown: any[];
  mountingTypeDrop: any[];
  meterManifracture: any[];
  meterModel: any[];
  lastTradedPrice: number | null;
  minRate: number | null;
  maxRate: number | null;
  timeBlockData: any;
  timeBlockLoading: boolean;
  wheelingCharge: number | null;
  buyerTransactionCharges: number | null;
  sellerTransactionCharges: number | null;
  discomRate: number | null;
  feedInTarrif:number|null;
  intradayObj:MarketTypeObj,
  dayAheadObj:MarketTypeObj
}

const initialState: InitialState = {
  error: false,
  isLoading: false,
  message: undefined,
  dataChanged: false,
  userIdData: [],
  userId: 0,
  states: undefined,
  stateId: undefined,
  discomeId: undefined,
  feederdropdown: [],
  meterNoDropdown: [],
  mountingTypeDrop: [],
  solarMfgdropdown: [],
  solarTypedropdown: [],
  substationdropdown: [],
  tranformerdropdown: [],
  meterManifracture: [],
  meterModel: [],
  lastTradedPrice: null,
  minRate: null,
  maxRate: null,
  timeBlockData: [],
  timeBlockLoading: false,
  buyerTransactionCharges: null,
  wheelingCharge: null,
  sellerTransactionCharges: null,
  discomRate: null,
  feedInTarrif:null,
  intradayObj:IMarketTypeObj,
  dayAheadObj:IMarketTypeObj
};

export const userIdDropDown: any = createAsyncThunk(
  "dropDown/userIdDropDown",
  async (thunkApi: any) => {
    try {
      const response = undefined;
      return response;
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(
          setError({
            status: error.response?.data.status,
            message: error.response?.data.message,
          })
        );
        return thunkApi.rejectWithValue({
          status: error.response?.data.status,
          message: error.response?.data.message,
        });
      }
      thunkApi.dispatch(
        setError({
          status: 0,
          message: error.message,
        })
      );
      return thunkApi.rejectWithValue({
        status: 0,
        message: error.message,
      });
    }
  }
);

export const dropdownConfig: any = createAsyncThunk(
  "list/dropdownConfig",
  async (data: any, thunkApi: any) => {
    try {
      const response: any = await getDropdownConfigtData();
      if (response.code === 200 && response.data) {
        return response?.data;
      } else {
        const errorMessage = response.data?.message;

        // toast(errorMessage)
        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));

        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        toast(error.message);
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const dropdownTimeBlock: any = createAsyncThunk(
  "dropdown/dropdownTimeBlock",
  async (data: any, thunkApi: any) => {
    try {
      const filterState = { ...data };

      const queryString = stringifyObjectToRequestQuery(filterState);

      const response: any = await getTimeBlockDropDownApi(queryString);

      // thunkApi.dispatch(updateErrorCode(response.code))

      if (response.code === 200 && response.data) {
        return response?.data; // Resolve the Promise with the successful response
      } else {
        const errorMessage = response.message;

        //   thunkApi.dispatch(updateError(errorMessage));
        //   thunkApi.dispatch(updateErrorCode(response?.code));

        return thunkApi.rejectWithValue(errorMessage);
      }
    } catch (_error) {
      const error = _error as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        thunkApi.dispatch(setError(error.response?.data.message));
        // thunkApi.dispatch(updateError(error.response?.data.message))
        thunkApi.dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
        return thunkApi.rejectWithValue(error.response?.data.message);
      } else {
        // toast.error(error.message)
        thunkApi.dispatch(updateError(error.message));
      }
      thunkApi.dispatch(setError(error.message));
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const dropDownSlice = createSlice({
  name: "dropdownSlicer",
  initialState,
  reducers: {
    resetChangedState(state) {
      state.dataChanged = false;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = true;
      state.message = action.payload.message;
    },
    resetError: (state) => {
      state.error = false;
      state.message = undefined;
    },
    updateUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload;
    },
    updateStateId: (state, action: PayloadAction<any>) => {
      state.stateId = action.payload;
    },
    updateDiscomId: (state, action: PayloadAction<any>) => {
      state.discomeId = action.payload;
    },
    resetDropDownState(state) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userIdDropDown.fulfilled, (state, action) => {
      state.userIdData = action.payload;
      state.isLoading = false;
      console.log(action.payload);
    });
    builder.addCase(userIdDropDown.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(userIdDropDown.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload || "Error Occured";
    });

    builder.addCase(dropdownConfig.fulfilled, (state, action) => {
      // state.userIdData = action.payload;
      state.isLoading = false;

      if (action.payload) {
        state.feederdropdown = action.payload?.feeder;
        state.meterNoDropdown = action.payload?.meters;
        state.mountingTypeDrop = action.payload?.mounting_type;
        state.tranformerdropdown = action.payload?.transformer;
        state.substationdropdown = action.payload?.substation;
        state.solarMfgdropdown = action.payload?.module_manufacturer;
        state.solarTypedropdown = action.payload?.module_type;
        state.meterNoDropdown = action.payload?.meters;
        state.meterManifracture = action.payload?.meter_manufacturer;
        state.meterModel = action.payload?.meter_model;
        state.lastTradedPrice = action.payload?.lastTradedPrice;
        state.minRate = action.payload?.tariffMinRate;
        state.maxRate = action.payload?.tariffMaxRate;
        state.wheelingCharge = action.payload?.wheelingCharges;
        state.buyerTransactionCharges = action.payload?.buyerTransactionCharges;
        state.sellerTransactionCharges =
          action.payload?.sellerTransactionCharges;
        state.discomRate = action.payload?.rate;
        state.feedInTarrif=action.payload?.feedInTarrif
        state.intradayObj=action.payload?.intraday;
        state.dayAheadObj=action.payload?.day_ahead;
        
      }
    });
    builder.addCase(dropdownConfig.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dropdownConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload || "Error Occured";
    });

    builder.addCase(dropdownTimeBlock.fulfilled, (state, action) => {
      // state.userIdData = action.payload;
      state.timeBlockLoading = false;
      state.timeBlockData =
        action.payload &&
        Array.isArray(action.payload) &&
        action.payload?.length > 0
          ? action.payload?.filter((cur: any) => !cur?.isTimelapsed)
          : [];
    });

    builder.addCase(dropdownTimeBlock.pending, (state, action) => {
      state.timeBlockLoading = true;
      state.timeBlockData = [];
    });
    builder.addCase(dropdownTimeBlock.rejected, (state, action) => {
      state.timeBlockLoading = false;
      state.message = action.payload || "Error Occured";
      state.timeBlockData = [];
    });
  },
});

export const {
  setError,
  resetError,
  resetChangedState,
  updateUserId,
  updateStateId,
  updateDiscomId,
  resetDropDownState,
} = dropDownSlice.actions;
export const dropDownReducerState = (state: RootState) => state.dropDown;
export default dropDownSlice.reducer;
