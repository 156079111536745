import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'


type Props = {}
const IMG_P2P_ENERGY_OUR_PRODUCTS=toAbsoluteUrl('/asset/image/p2p_enenrgy_our_products.png')
const DATA = [
    {
        "title": "Intraday Trading",
        "description": "Buy and Sell energy within the same day to balance short term grid fluctuations based on real-time demand and supply."
    },
    {
        "title": "Day Ahead Trading",
        "description": "Trade energy a day in advance, allowing secure pricing and better planning for energy needs before delivery."
    },
    // {
    //     "title": "Long Term PPA",
    //     "description": "A long-term contract between an energy producer and buyer, ensuring stable energy pricing over a year to reduced market risk."
    // }
]

const P2pEnergyOurProducts = (props: Props) => {
    return (
        <div className='h-[500px] p-8' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_OUR_PRODUCTS + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    {/* <span className='text-sm uppercase text-white '>Peer-to-Peer Energy Trading for Smart Savings</span>
                    <span className='h-[2px] bg-primary w-[50px]'></span> */}
                    <span className='font-bold text-3xl text-white'>Key Products Features Of Peer-to-Peer Solar Energy Trading</span>
                </div>
                <div className=' flex justify-center items-center gap-4 mt-16' >
                    {DATA.map((d: any) => {
                        return <div className='w-[400px] p-8 bg-white rounded-xl space-y-4 ' key={d.title}>
                            <div className='text-primary text-center font-bold'>{d.title}</div>
                            <div className='text-center'>{d.description}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default P2pEnergyOurProducts