import { Button } from "src/components/ui/button";
import { Card, CardHeader } from "../../../components/ui/card";
import React, { useEffect, useMemo, useState } from "react";
import {
  AlignJustify,
  Bell,
  BellIcon,
  History,
  LogOut,
  MapPin,
  Minus,
  RotateCcw,
  Search,
  User,
} from "lucide-react";
import Logo from "src/assets/images/Electreecity-LogoPNG.png";
import { getAuth, useAuth } from "../../Auth/Core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, otherMenuForHeading } from "../SideBar/modules";
import dayjs from "dayjs";
import { SidebarProps } from "../SideBar/Sidebarlayout";
import { convertTo12HourFormat, convertToCamelCase } from "../../../helpers";
import SwitchUser from "../../../assets/images/SwitchUser.svg";
import NotificationBell from "./NotificationBell";
import Marquee from "react-fast-marquee";
import { RootState } from "src/redux/store";
import { dropDownReducerState } from "src/redux/Slicer/dropDownSlice";
import { useSelector } from "react-redux";

const Headers = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const {auth } = useAuth();

  const { pathname } = useLocation();
  const menuOptions = [...Menu, ...otherMenuForHeading];

  const heading = useMemo(() => {
    for (const cur of menuOptions) {
      if (cur.path && pathname === cur.path) {
        return cur.name;
      } else if (cur.options && cur.options.length > 0) {
        for (const ind of cur.options) {
          if (ind.path && pathname.includes(ind.path)) {
            return `${cur.name}-${ind.name}`;
          }
        }
      }

    }
    return ""; // Default case if no match found
  }, [menuOptions, pathname]);
  const {  intradayObj, dayAheadObj } = useSelector(
    (state: RootState) => dropDownReducerState(state)
  );

  // const [currentTime, setCurrentTime] = useState(new Date());


  // const formattedDate = dayjs(currentTime).format("DD-MM-YYYY");
  // const formattedTime = dayjs(currentTime).format("HH:mm ");
  // const navigate = useNavigate();

  const startTime=convertTo12HourFormat(intradayObj?.startTime)
  const endTime=convertTo12HourFormat(intradayObj?.endTime)

  return (
    <header className="w-full sticky top-0 z-999 flex bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-md drop-shadow-md lg:rounded-md">
      <nav className="w-full flex items-center justify-between h-12 px-1  md:py-4 md:px-6 space-x-6">
        <div className="text-sm text-textLightBlack font-normal flex  items-center space-x-4">
          <div className="md:hidden sm:flex">
            <div
              className=" p-1 bg-gray-200 rounded-md "
              onClick={() => setSidebarOpen(!sidebarOpen)}
              data-testid="alignJustify"
            >
              <AlignJustify />
            </div>
          </div>
          <div className="whitespace-nowrap"> {heading}</div>
        </div>

        <div className="mb-2 flex justify-center items-center">
                {/* <span className=" text-light_red text-sm font-bold whitespace-nowrap">News Just In</span>
                <span className=" text-sm font-normal text-textBlack mx-1">:</span> */}
                <Marquee pauseOnHover={true} >
                    <span className="  text-sm font-normal text-text_primaryDarkBlue cursor-pointer">
                    Energy scheduling should be completed at least four-time blocks before the intended schedule. Trading hours are from <b>{startTime} to {endTime}</b>  IST.
                    </span>
                </Marquee>
            </div>

        <div className="flex space-x-2 items-center">
        <div
            className="flex text-textLightBlack text-sm font-normal whitespace-nowrap cursor-default"
            title={`Platform Id : ${auth?.userCode}`}
          >
            {" "}
            {auth?.user_name || "-"} {`(${auth?.consumerNumber || ""})`}
          </div>
          

          <div className=" rounded-full p-1 whitespace-nowrap">
            <Link to="/user-selection" title="Switch User">
              {/* <User color="#FFFFFF" size={20} /> */}
              <img src={SwitchUser} alt="switchUser" className="w-6 h-6" />
            </Link>
          </div>
          <NotificationBell />

        </div>
      </nav>
    </header>
  );
};

export default Headers;
